@use './variables' as *;

[class^='banner'] {
  height: $pattern-item-dimension-standard * 4;

  &.solid {
    height: $pattern-item-dimension-standard * 1.5;
  }
}

.banner-blue {
  background-color: $rainbow-blue;
}

.banner-red {
  background-color: $rainbow-red;
}

.banner-orange {
  background-color: $orange-light;
}

.banner-green {
  background-color: $rainbow-green;
}

.pattern-row {
  display: flex;
  opacity: .5;
}

.quarter-disc {
  min-width: $pattern-item-dimension-standard;
  height: $pattern-item-dimension-standard;
  background-color: $application-background-color;

  &.top-left {
    border-top-left-radius: $border-radius-one-hundred-percent;
  }

  &.top-right {
    border-top-right-radius: $border-radius-one-hundred-percent;
  }

  &.bottom-left {
    border-bottom-left-radius: $border-radius-one-hundred-percent;
  }

  &.bottom-right {
    border-bottom-right-radius: $border-radius-one-hundred-percent;
  }
}

.pattern-disc-container div {
  min-width: $pattern-item-dimension-standard;
  height: $pattern-item-dimension-standard;
  border-radius: 50%;
  background-color: $application-background-color;
}

/* Breakpoint Small */

@media (min-width: $breakpoint-sm) {

  [class^='banner'] {
    height: $pattern-item-dimension-standard * 8;

    &.solid {
      height: $pattern-item-dimension-standard * 3;
    }
  }
}
