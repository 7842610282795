@use './variables' as *;

.projects {
  max-width: 75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
}

.project-container {
  margin: .625rem 1.25rem 1.5rem;
}

.project-details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 26.5rem;
  border: $border-width-medium solid $fifty-percent-gray;
  border-radius: .625rem;
  background-color: transparent;

  &:hover {
    border-color: $rainbow-green;
    box-shadow: 0 0 1rem $rainbow-green;
  }
}

.project-image {
  width: 14.375rem;
  border-radius: .375rem;
}

.project-info-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
}

.project-info-title {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: $letter-spacing-standard;
  color: $rainbow-green;
}

.project-info-text-container {
  line-height: 1.5rem;
  text-align: left;
  color: $application-font-color;
  font-size: 1rem;
}

.project-info-text {
  margin-top: .5rem;
}

.stack-container {
  margin-top: 1.25rem;
}

.stack-title {
  font-weight: 600;

  &.front-end {
    color: $orange-light;
  }

  &.back-end {
    color: $rainbow-red;
  }
}

.project-footer {
  padding-bottom: .75rem;
  margin-top: .5rem;
}

.project-footer-title {
  padding-top: .25rem;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: $letter-spacing-standard;
}

.project-footer-link {
  display: block;
  width: 100%;
  padding: .25rem 0;
  border: $border-width-small solid $fifty-percent-gray;
  border-radius: .5rem;
  margin-top: .75rem;
  font-family: 'Varela Round', sans-serif;
  font-size: .75rem;
  font-weight: 700;
  letter-spacing: $letter-spacing-standard;
  text-decoration: none;
  background-color: transparent;
  color: $application-font-color;

  &:nth-child(2) {
    border-color: $rainbow-yellow;
  }

  &:nth-child(3) {
    border-color: $rainbow-blue;
  }

  &:link,
  &:visited,
  &:active {
    color: $application-font-color;
  }

  &:hover {
    background-color: $application-font-color;
    color: $application-background-color;
  }
}

/* Breakpoint Small */

@media (min-width: $breakpoint-sm) {

  .projects {
    padding-bottom: 1rem;
  }

  .project-footer {
    padding: 0 1.25rem;
  }
}
