@use './variables' as *;
@use './banners' as *;
@use './footer' as *;
@use './header' as *;
@use './not-found' as *;
@use './projects' as *;

html,
body {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Alegreya Sans', sans-serif;
  text-align: center;
  background-color: $blue-muted;
  color: $application-font-color;
}

main {
  padding-bottom: 1rem;
  background-color: $application-background-color;
}

h1,
h2 {
  margin-block-start: initial;
  margin-block-end: initial;
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

.name-and-occupations {
  padding-top: 4rem;
  margin: auto auto 1rem;
  letter-spacing: .25rem;
}

.name {
  font-size: 1.75rem;
}

.occupations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.25rem;
  color: $rainbow-green;
}

.pipe {
  display: none;
  color: $fifty-percent-gray;
}

.headshot-img {
  width: 150px;
  border: .25rem solid $fifty-percent-gray;
  border-radius: 50%;
}

main section ul {
  max-width: 37.5rem;
  padding-bottom: .5rem;
  border-bottom: .25rem dotted $application-font-color;
  margin: 1rem;

  li {
    text-align: initial;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}

.text-link {
  text-decoration: none;
  color: $rainbow-yellow;

  &:hover {
    text-decoration: underline;
  }

  &:link,
  &:visited,
  &:focus,
  &:active {
    color: $rainbow-yellow;
  }
}

.section-title {
  margin: 1.75rem auto .625rem;
  font-size: 1.25rem;
  letter-spacing: .5vw;
}

p {
  width: 85%;
  max-width: $element-width-large;
  margin: auto auto 2rem;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

/* Breakpoint Small */

@media (min-width: $breakpoint-sm) {

  .name {
    font-size: 3rem;
  }

  .occupations {
    flex-direction: row;
    font-size: 1.75rem;
  }

  .pipe {
    display: block;
  }

  main section ul {
    max-width: 31.25rem;
    margin: 2rem auto;

    li {
      font-size: 1.5rem;
    }
  }

  .section-title {
    margin-top: 2.25rem;
    font-size: 2rem;
    letter-spacing: .25rem;
  }

  p {
    width: 75%;
    margin-bottom: 3rem;
    font-size: 1.5rem;
  }
}

/* Breakpoint Medium */

@media (min-width: $breakpoint-md) {

  p {
    width: 60%;
  }

  main section ul {
    max-width: 39.5rem;
  }
}
