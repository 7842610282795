/* Breakpoints from Bootstrap */

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

/* Color Mixes */

$white: #fff;
$black: #000;
$four-percent-gray: #f5f5f5;
$fifty-percent-gray: #7f7f7f;
$ninety-five-percent-gray: #0d0d0d;
$black-screen: rgb(0 0 0 / 93%);
$blue-muted: #7073ff;
$orange-muted: #ff9600;
$orange-light: #f48d4d;
$rainbow-red: #f27089;
$rainbow-orange: #fca778;
$rainbow-yellow: #fbde84;
$rainbow-green: #9ac479;
$rainbow-blue: #74a6cf;

$application-font-color: $four-percent-gray;
$application-background-color: $ninety-five-percent-gray;

/* Border Radius */

$border-radius-one-hundred-percent: 100%;

/* Letter Spacing */

$letter-spacing-standard: .25vw;

/* Dimensions */

$border-width-small: 1px;
$border-width-medium: 2px;
$element-width-standard: 15rem;
$element-width-large: 62.5rem;
$pattern-item-dimension-standard: 25px;
