@use './variables' as *;

footer {
  background-color: $application-background-color;
}

.information-box {
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: .75rem auto 1.5rem;

  a {
    display: inline-block;
    margin: 1.5rem;
  }
}

.information-text {
  margin-top: .5rem;
  font-family: 'Varela Round', sans-serif;
  letter-spacing: .125rem;
}

.credit {
  margin: 1.25rem auto;
  font-family: 'Roboto', sans-serif;
  letter-spacing: $letter-spacing-standard;
}

.stripes-container {
  height: 3.125rem;

  div {
    height: .1875rem;
    background-color: $orange-muted;

    &:nth-child(2),
    &:nth-child(6) {
      background-color: $rainbow-blue;
    }

    &:nth-child(3),
    &:nth-child(5) {
      background-color: $rainbow-green;
    }

    &:nth-child(4) {
      background-color: $rainbow-yellow;
    }
  }
}

/* Breakpoint Small */

@media (min-width: $breakpoint-sm) {

  .information-box {
    flex-direction: row;
    padding: 1rem 1.5rem;
    margin-bottom: 2.5rem;
  }
}

/* Breakpoint Large */

@media (min-width: $breakpoint-lg) {

.information-box a {
    margin: 1.5rem 2.5rem;
  }
}
