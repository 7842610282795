@use './variables' as *;

nav {
  position: fixed;
  right: initial;
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 1;
  padding: .5rem .125rem;
  background-color: $rainbow-yellow;

  a {
    font-family: 'Varela Round', sans-serif;
    letter-spacing: $letter-spacing-standard;
    text-decoration: none;
    color: $application-background-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Breakpoint Small */

@media (min-width: $breakpoint-sm) {

  nav {
    right: 0;
    justify-content: flex-end;
    padding: .5rem .75rem .5rem .5rem;

    a {
      margin: auto .75rem;
    }
  }
}
